import React, { FC, useEffect } from 'react';
import {
    HomeHeroStoryFragment,
    HomeQuery,
    HomeQueryVariables,
    SponsorsQuery,
    SponsorsQueryVariables,
    useHomeQuery,
    useSponsorsQuery,
} from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import loadIntlMessages from '../utils/loadIntlMessages';
import { DEFAULT_REVALIDATION_TIME } from './[...uri]';
import { fetchCraftData, gtm, PreviewData, SponsorsBanner, SponsorSet } from 'shared';
import { Box, DestinationListItem, Flex, Grid, ListDivider, Stack, VStack, Wrapper } from 'designsystem';
import prefetchGlobals from '../utils/prefetchGlobals';
import Error from 'next/error';
import HomeStoriesEvents from '../components/HomeStoriesEvents';
import HomeHero from '../components/HomeHero';
import HomeCTA from '../components/HomeCTA';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { useIntl } from 'react-intl';

// Testing deployment

const Home: FC = () => {
    const { locale, asPath } = useRouter();
    const { data } = useHomeQuery({ site: [siteHandleByLocale[locale]] });
    const entry = data?.entry;
    useEffect(() => {
        gtm.pageView(asPath, {
            page_pillar: 'professionals',
            page_language: locale,
        });
    }, [asPath, locale]);
    const { formatMessage } = useIntl();

    if (entry?.__typename !== 'professionalsHomepage_professionalsHomepage_Entry') {
        return <Error statusCode={500} title="No homepage entry found in craft" />;
    }

    const [, secondHeroStory, thirdHeroStory] = entry.homeHeroStories;

    return (
        <>
            <NextSeo
                title="IDFA Professionals"
                titleTemplate="%s"
                description="IDFA Professionals connects filmmakers and professionals from the international industry to create and share the creative documentaries of tomorrow."
            />
            <HomeHero
                events={entry?.homeHeroEvents}
                stories={entry?.homeHeroStories as HomeHeroStoryFragment[]}
                keyDates={entry?.homeHeroKeyDates}
            />
            <Box display={['block', null, null, 'none']}>
                {secondHeroStory?.__typename === 'professionalsStories_story_Entry' &&
                    thirdHeroStory?.__typename === 'professionalsStories_story_Entry' && (
                        <HomeStoriesEvents
                            events={entry.homeHeroEvents}
                            stories={[secondHeroStory, thirdHeroStory]}
                            keyDates={entry.homeHeroKeyDates}
                        />
                    )}
            </Box>
            <Wrapper my={[17, null, null, 20]}>
                <VStack spacing={17}>
                    <Grid w="100%">
                        <Flex gridColumn={['1 / -1', null, null, '1 / span 5']}>
                            <Stack spacing={0} divider={<ListDivider />} w="100%">
                                {entry?.homeDestinationList?.map(item => (
                                    <Link href={item.linkTo.element?.uri ?? item.linkTo.url ?? '/404'} key={item.id}>
                                        <DestinationListItem
                                            title={item.linkTo.element ? item.linkTo.element?.title : item.label}
                                            textButtonProps={{
                                                children: item.linkTo.element
                                                    ? item.label
                                                    : formatMessage({ defaultMessage: 'Lees meer' }),
                                            }}
                                        />
                                    </Link>
                                ))}
                            </Stack>
                        </Flex>
                        <Flex
                            gridColumn={['1 / -1', null, null, '9 / -1']}
                            alignItems="flex-end"
                            flexDirection="column"
                            order={[-1, null, 0]}
                            pb={[17, null, null, 0]}
                            alignSelf={['start', null, null, 'center']}
                        >
                            <SponsorsBanner maxW="368px" sponsorSet={entry?.sponsorBannerSet[0] as SponsorSet} />
                        </Flex>
                    </Grid>
                    {entry?.homeCta && <HomeCTA cta={entry.homeCta[0]} />}
                </VStack>
            </Wrapper>
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale, previewData }) => {
    const queryClient = new QueryClient();
    const vars = { site: [siteHandleByLocale[locale]] };

    await queryClient.prefetchQuery(useHomeQuery.getKey(vars), () =>
        fetchCraftData<HomeQuery, HomeQueryVariables>({
            query: useHomeQuery.document,
            variables: vars,
            previewData: previewData as PreviewData,
        })
    );

    await prefetchGlobals(queryClient, locale);
    await queryClient.prefetchQuery(useSponsorsQuery.getKey({}), () =>
        fetchCraftData<SponsorsQuery, SponsorsQueryVariables>({
            query: useSponsorsQuery.document,
            variables: {},
            previewData: previewData as PreviewData,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default Home;
